import { Controller } from '@hotwired/stimulus';
import {
  rulesMatrix,
  buildSelect,
  buildText
} from '../../application/javascripts/helpers/hex_protect_rules_helper';

export default class extends Controller {
  static targets = ['additionalFields']
  static values = {
    delete: Boolean
  }

  connect () {
    this.element[this.identifier] = this
    this.rulesMatrix = rulesMatrix()
    this.element.classList.add('hexo-columns')
    this.element.append(this._buildConditionTypeField())
    if (!this.hasAdditionalFieldsTarget) {
      this.element.append(this._additonalFieldsElement())
    }
    this._appendDeleteButton()
  }

  change (value) {
    const rule = this.rulesMatrix[value]
    this.additionalFieldsTarget.innerHTML = ''
    rule.fields.forEach((field) => {
      this.additionalFieldsTarget.append(this._buildInput(field))
    })
  }

  timeRangeChange (value) {
    if (value == 'current month') {
      this._hideField('time')
    } else {
      this._showField('time')
    }
  }

  comparisonChange (value) {
    if (['is in', 'is not in'].includes(value)) {
      this._showSetsField()
    } else {
      this._showValueField()
    }
  }

  buildData () {
    let data = []
    let valid = true

    this.element.querySelectorAll('input:enabled').forEach((input) => {
      const name = input.name
      const value = input.value
      const parent = input.closest('.hexo-field')
      if(parent.className.includes('hexo-field--error')) return

      if (value == '') {
        valid = false
        parent.classList.add('hexo-field--error')
      } else {
        parent.classList.remove('hexo-field--error')
      }

      switch (name) {
        case 'condition_type':
          data.push(this.rulesMatrix[value].name)
          break
        case 'filter':
          if (!value.match(/- none -/)) {
            data.push('per')
          }
          break
        case 'time':
          data.push('in')
          break
        case 'time_range':
          if (value === 'current month') {
            data.push('in')
          }
          break
        default:
          data.push(value)
      }
    })

    return { valid: valid, data: data.join(' ') }
  }

  delete () {
    this.element.remove()
  }

  _showSetsField () {
    this._hideField('value')
    this._showField('set_name')
  }

  _showValueField () {
    this._hideField('set_name')
    this._showField('value')
  }

  _hideField (key) {
    const el = this._findFieldElement(key)

    if (el) {
      const field = this._findField(el)
      el.classList.add('hidden')

      if (field) {
        field.disabled = true
      }
    }
  }

  _showField (key) {
    const el = this._findFieldElement(key)

    if (el) {
      const field = this._findField(el)
      el.classList.remove('hidden')

      if (field) {
        field.disabled = false
      }
    }
  }

  _findFieldElement (key) {
    return this.element.querySelector('*[data-field-name="' + key + '"]')
  }

  _findField (el) {
    return el.querySelector('input')
  }

  _buildInput (field) {
    switch (field.type) {
      case 'select':
        return buildSelect(field.name, field.label, this._getOptions(field))
      default:
        return buildText(field.name, field.label)
    }
  }

  _getOptions (field) {
    switch (field.name) {
      case 'set_name':
        return this._currentSets().map((o) => {
          return {
            key: o.name,
            value: o.name
          }
        })
      case 'value_type':
        if (field.currencies) {
          return this._getCurrencies().map((c) => {
            return {
              key: c,
              value: c
            }
          })
        }
      default:
        return field.options
    }
  }

  _getCurrencies () {
    const newRuleController = this.element.closest('*[data-controller="hex-protect--new-rule"]')

    if (newRuleController) {
      return newRuleController['hex-protect--new-rule'].supportedCurrencies()
    }

    return []
  }

  _currentSets () {
    const setsController = document.querySelector('*[data-controller="hex-protect--sets-render"]')

    if (setsController) {
      return setsController['hex-protect--sets-render'].availableSets()
    }

    return []
  }

  _buildConditionTypeField () {
    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-column')
    wrapper.classList.add('hexo-column__t--12')
    wrapper.dataset.controller = 'fields--select'
    wrapper.setAttribute('data-fields--select-target', 'parent')
    wrapper.setAttribute('data-fields--select-change-target-value', this.identifier)
    wrapper.setAttribute('data-fields--select-change-method-value', 'change')

    const selectWrapper = document.createElement('div')
    selectWrapper.classList.add('hexo-field')
    selectWrapper.classList.add('hexo-field__no-margin')
    selectWrapper.setAttribute('data-fields--select-target', 'field')
    selectWrapper.dataset.action = 'click->fields--select#open'

    const label = document.createElement('label')
    label.classList.add('hexo-field__label')
    label.innerHTML = 'if'

    const select = document.createElement('select')
    select.name = 'condition_type'
    select.classList.add('hexo-field__input')
    select.setAttribute('data-fields--select-target', 'select')

    Object.entries(this.rulesMatrix).forEach(([k,v]) => {
      const option = document.createElement('option')
      option.value = k
      option.innerHTML = v.name

      select.append(option)
    })

    selectWrapper.append(label)
    selectWrapper.append(select)

    wrapper.append(selectWrapper)

    return wrapper
  }

  _additonalFieldsElement () {
    const el = document.createElement('div')
    el.classList.add('hexo-column')
    el.classList.add('hexo-column__t--12')

    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-columns')
    wrapper.setAttribute('data-hex-protect--rule-condition-target', 'additionalFields')

    el.append(wrapper)

    return el
  }

  _appendDeleteButton () {
    if (this.hasDeleteValue && this.deleteValue === true) {
      this.element.append(this._buildDeleteButton())
    }
  }

  _buildDeleteButton () {
    const wrapper = document.createElement('div')
    wrapper.classList.add('hexo-column')
    wrapper.classList.add('hexo-column__t--12')

    const button = document.createElement('button')
    button.type = 'button'
    button.classList.add('hexo-btn-default')
    button.classList.add('hexo-btn-default__outline')
    button.classList.add('hexo-btn-default__danger')
    button.dataset.action = 'click->hex-protect--rule-condition#delete'
    button.innerHTML = 'Remove'

    wrapper.append(button)

    return wrapper
  }
}
